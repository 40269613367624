import { useCallback } from 'react';
import { useTranslation as useTranslationOrigin } from 'react-i18next';

const useTranslation = ns => {
    const { t } = useTranslationOrigin();
    return useCallback(
        (text, overWriteNs = ns, variables) => {
            if (typeof overWriteNs === 'object' && !variables) {
                variables = overWriteNs;
                overWriteNs = ns;
            }
            if (!overWriteNs) {
                return t(text, variables);
            }
            const message = `${overWriteNs};${text}`;
            return message === t(message, variables) ? text : t(message, variables);
        },
        [t],
    );
};

export default useTranslation;
