export const categoryName = ({ ...restProps } = {}) => {
    return {
        Header: 'Category Name',
        headerClassName: 'flex-center',
        accessor: 'categoryName',
        className: 'flex-center',
        width: 100,
        ...restProps,
    };
};

export const targetName = ({ ...restProps } = {}) => {
    return {
        Header: 'Target Name',
        headerClassName: 'flex-center',
        accessor: 'targetName',
        className: 'flex-center text-ellipsis',
        ...restProps,
    };
};
